import product from '@/components/product/index.vue'
import {mapGetters} from "vuex";
export default {
  name: "top-sales",
  components:{
    product
  },
  computed:{
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      favourites: 'favorites/whichList',

      popularProducts:`card/popularProducts`,
      popularLoader:`card/popularLoader`,

    }),
    list(){
      if (this.isAuthenticated) {
        if (!this.popularProducts) return []
        if (!this.favourites) return []
        return this.popularProducts.map(e => {
          const item = this.favoriteProducts.find(t => t.id === e.id)
          e.selected = !!item
          return e
        })
      } else {
        return this.popularProducts
      }
    },
    favoriteProducts(){
      let arr = []
      if(this.isAuthenticated){
        for(let j in this.favourites){
          for(let i in this.favourites[j].products){
            arr.push(this.favourites[j].products[i])
          }
        }
        return arr
      }
    }
  }
}