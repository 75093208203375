import { Carousel, Slide } from 'vue-carousel';
import {mapGetters} from "vuex";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "main-slider",
  components:{
    Carousel,
    VueSlickCarousel,
    Slide
  },
  data() {
    return {
      settings: {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }
  },
  computed:{
    ...mapGetters({
      banners:`setting/bannerList`
    })
  },
  methods:{
    nextSlide() {
      this.$refs.slick.next();
    },

    prevSlide() {
      this.$refs.slick.prev();
    },
  }
}