import novelty from './components/novelty/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "news",
  components:{
    novelty
  },
  computed:{
    ...mapGetters({
      mainNews:`setting/mainNews`
    })
  },
  created() {
    this.fetchMainNews()
  },
  methods: {
    ...mapActions({
      fetchMainNews: `setting/GET_MAIN_NEWS`
    }),
    ...mapMutations({
    })
  }
}