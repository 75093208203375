import mainSlider from "./components/main-slider/index.vue"
import topSales from './components/top-sales/index.vue'
import stock from './components/stock/index.vue'
import news from './components/news/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import VueHead from 'vue-head'
import Vue from 'vue'

Vue.use(VueHead)

export default {
  name: "home",
  data() {
    return {
      params: {
        title: '',
        description: '',
        keywords: '',
        image: ''
      },
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      return [
        {rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical'},
        {rel: 'alternate', hreflang: 'uk-UA', href: window.location.origin+  '/ru', id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin + '/en', id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin, id: 'x-default'},
      ]
    }
  },
  components: {
    mainSlider,
    topSales,
    stock,
    news
  },
  created() {
    this.fetchPage('home').then(()=>{
      this.setMeta()
    })
    this.fetchPopularProducts()
    this.fetchMainNews()
  },
  computed:{
    ...mapGetters({
      mainNews:`setting/mainNews`,
      page: 'pages/page'
    })
  },
  methods: {
    setMeta(){
      this.params.title = this.page.meta.meta_title !== '' ? this.page.meta.meta_title : 'ICHIBAN STORE',
      this.params.description = this.page.meta.meta_description !== '' ? this.page.meta.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
      this.params.keywords = this.page.meta.meta_keywords,
      this.params.image = this.page.image !== '' ? this.page.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
    ...mapActions({
      fetchPage: 'pages/GET_PAGE',
      fetchPopularProducts: `card/GET_POPULAR_PRODUCTS`,
      fetchMainNews: `setting/GET_MAIN_NEWS`
    }),
    ...mapMutations({
      setPopularProducts:``
    })
  }

}