import banner from './components/banner/index.vue'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "stock",
  components:{
    banner
  },
  created() {
    this.fetchSales()
  },
  computed:{
    ...mapGetters({
      salesList:`setting/salesList`
    })
  },
  methods:{
    ...mapActions({
      fetchSales:`setting/GET_SALES_LIST`
    })
  }
}